import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { enumReducerType } from '../../../state/common';
import CardInsightsFull from '../Cards/CardInsightsFull';
import Pagination from '../Navigation/Pagination/Pagination';
import FilterContent from './../FilterContent/FilterContent';
import { fetchCardBlockLargeList } from '../../../service';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { set } from 'lodash';
const CardBlockCardListing = ({ items, columns, ...props }) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') ?? 1;
  const { filters, title, relevantFilter, tagsToShow } = fields;
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const navigation = useNavigate();
  const navigate = (page) => {
    if (location?.pathname?.includes('insights')) {
      navigation({
        pathname: location?.pathname,
        search: createSearchParams({ page: page }).toString(),
      });
    }
  };

  const [totalItems, setTotalItems] = useState(null);
  const [showTags, setShowTags] = useState([]);
  const selectSearchedData = useSelector((state) => state.filterReducer.searcheddata);
  const searchedValue = useSelector((state) => state?.filterReducer?.searchterm);
  const selectedFilterpayloadinsight = useSelector(
    (state) => state.filterReducer.filterpayloadinsight
  );
  const allfiltoptionswithduplicates = useSelector(
    (state) => state.masterReducer.allfiltoptionswithduplicates
  );
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const sendDataToParent = async (index) => {
    const result = await fetchCardBlockLargeList(
      selectedFilterpayloadinsight,
      6,
      page - 1,
      'en',
      false,
      false,
      searchedValue
    );
    const type = enumReducerType.SET_SEARCHED_LARGE_LIST_DATA;
    const payload = result.data;
    const action = { type, payload };
    dispatch(action);
  };

  useEffect(() => {
    const type = enumReducerType.SET_CONTENT_TYPE_LIST;
    const payload = filters.filter((item) => item?.name === 'Types')[0]?.fields?.values;
    const action = { type, payload };
    dispatch(action);
  }, [filters]);
  useEffect(() => {
    setTotalItems(selectSearchedData.totalCount);
    setData(selectSearchedData.searchResult);
  }, [selectSearchedData]);
  useEffect(() => {
    if (tagsToShow.length > 0) {
      const dataTags = tagsToShow.map((tag) =>
        allfiltoptionswithduplicates
          .flat()
          .filter((item) => item?.filt?.toUpperCase() === tag?.name?.toUpperCase())
          .map((item) => ({ name: item?.name, id: item?.id }))
      );
      const eleTags = dataTags.flat();
      const elems = eleTags.filter(function (a) {
        let key = a?.name + '|' + a?.id;
        if (!this[key]) {
          this[key] = true;
          return true;
        }
      }, Object.create(null));
      setShowTags(elems.slice(0, 2));
    }
  }, [allfiltoptionswithduplicates]);

  useEffect(() => {
    page ? setCurrentPage(parseInt(page)) : setCurrentPage(1);
    sendDataToParent();
    if (parseInt(page) === 1) handlePageChange(1);
  }, [page, currentPage]);

  const setPageParams = (page) => {
    navigate(page);
  };

  /**
   * @description to load selected page content
   * @param {number} selectedPage - selected page number
   * @returns {array} - page content
   */
  const handlePageChange = (selectedPage) => {
    const pageNo = selectedPage ?? 1;
    if (pageNo !== currentPage || pageNo === 1) {
      setPageParams(pageNo);
    }
  };
  return (
    <section
      className="card-block insights insights__listing"
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <FilterContent
          title={title?.value}
          filters={filters}
          totalItems={totalItems}
          limit={itemsPerPage}
          offset={currentPage - 1}
          sortOptions={relevantFilter}
        />
        <div className={`col columns columns__${columns} cards cards__${items.length}`}>
          {data &&
            data.map((item, id) => (
              <CardInsightsFull
                key={id}
                subTitle={item?.subTitle}
                title={item?.title}
                text={item?.text}
                ctaUrl={item?.url}
                contentTags={item?.contentTags}
                contentType={item?.contentType}
                solutionsTags={showTags}
                latest={item?.latest}
                img={item?.image?.src}
                altText={item?.image?.altText}
                componentName={componentName}
                item={item}
                fields={fields}
              />
            ))}
        </div>
        {totalItems > 0 && (
          <div className={`col columns columns__${columns} cards cards__${items.length}`}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={currentPage}
              onPageChanged={handlePageChange}
            />
          </div>
        )}
      </div>
    </section>
  );
};

CardBlockCardListing.defaultProps = {
  title1: '',
  items: [],
  columns: '3',
};

CardBlockCardListing.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  columns: PropTypes.oneOf(['1', '2', '3', '4']),
};

export default CardBlockCardListing;
