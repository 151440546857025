import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../../src/globals/buttons/Button';
import Tag from '../../../globals/buttons/Tag';
import ManageContentTypeTags from '../DetailsBlocks/ManageContentTypeTags';
import { getContentTypeTags, getCountryLangForOT } from '../../../utils/HelperUtils';
import { OtherLinkDataAttributes } from '../../../constants/gtm';
import rectangle963 from '../../../static/images/rectangle963.png';
import { isEmpty } from 'lodash';

const CardInsightsFull = ({
  title,
  subTitle,
  text,
  ctaUrl,
  altText,
  cssClass,
  solutionsTags,
  img,
  contentType = [],
  backgroundColor,
  componentName,
  item,
  fields,
}) => {
  const [ct, setCt] = useState([]);
  const selectorCT = useSelector((state) => state?.masterReducer?.allcontenttypes);
  const getCountry = getCountryLangForOT(location.pathname);
  useEffect(() => {
    if (selectorCT && selectorCT?.length > 0) {
      const filteredCT = contentType
        .map((item) => selectorCT?.filter((curr) => curr?.id === item).map((ct) => ct?.name))
        .flat();
      setCt(filteredCT);
    }
  }, [selectorCT]);

  return (
    <a
      href={ctaUrl}
      className={`card card__full ${cssClass} ${backgroundColor}`}
      {...OtherLinkDataAttributes({
        name: componentName,
        dataItemName: title,
      })}
    >
      <div className="card-body-image">
        {item?.IsLatest &&
          (img ? (
            <div className="button tag solid violet medium buttonlatest">Latest</div>
          ) : (
            <div className="button tag solid white medium buttonlatest">Latest</div>
          ))}
        {img ? (
          <img src={img} alt={altText || ''} />
        ) : (
          <img src={rectangle963} alt={'default images' || ''} />
        )}
      </div>
      <div className="card-body">
        <ManageContentTypeTags tags={getContentTypeTags(item, fields)} />
        <div className="card-content flex-column">
          <h3 className="title">{title}</h3>
          {subTitle && <div className="sub-title">{subTitle}</div>}

          {text && <p className="no-filter">{text}</p>}
        </div>
        <div className="carins">
          {solutionsTags &&
            solutionsTags?.map((tag, index) => (
              <Tag
                key={`${index}-${tag?.name}`}
                cssClass={tag?.cssClass}
                fontSize="small"
                icon={item?.fields?.icon || item?.fields?.icon?.src}
                text={tag?.name}
                componentName={componentName}
                dataItemName={title}
              />
            ))}
        </div>
        {item && !isEmpty(item?.Region) && item?.Region?.toLowerCase() === 'global' && (
          <Button
            text="Global"
            cssClass={'tag card-global'}
            fontSize="small"
            componentName={componentName}
            dataItemName={title}
          />
        )}
      </div>
    </a>
  );
};

CardInsightsFull.defaultProps = {
  img: '',
  latest: '',
  altText: '',
  contentTags: [],
  contentType: [],
  date: '',
  subTitle: '',
  title: '',
  text: '',
  ctaUrl: '',
  cssClass: '',
  backgroundColor: 'primary',
  solutionsTags: [],
};

CardInsightsFull.propTypes = {
  /** Featured image or brand symbol */
  img: PropTypes.string,
  altText: PropTypes.string,
  latest: PropTypes.string,
  /** Readtime of runtime tag */
  length: PropTypes.string,
  /** Content tag (i.e. webinar, article, or movie etc.) */
  contentTags: PropTypes.array,
  contentType: PropTypes.array,
  date: PropTypes.string,
  subTitle: PropTypes.string,
  cssClass: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  /** Solutions tags (3 max) */
  solutionsTags: PropTypes.array,
};

export default CardInsightsFull;
